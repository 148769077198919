export default {

    STORAGE_AUTH_TOKEN: "auth",

    //in _variables.scss, config.js and plugins/vuetify.js
    WIDTH_SM: 688,
    WIDTH_MD: 1160,//1016,
    WIDTH_LG: 1344,
    WIDTH_XL: 1344,//reserved

    // todo make FR version too
    ALLERGENS_REGEXP: /(milk|soy|lactose|hazelnuts|hazelnut|wheat|barley|ginger|peanuts|peanut|walnut|dairy|cashew)/isg,

    DEBOUNCE_INPUT_TIMEOUT: 800,

    // todo move URLS to backend
    LANGUAGES: [
        {
            name:"English",
            code:"en",
            host: "https://en.lebonheurparis.com"
        },
        {
            name:"Français",
            code:"fr",
            host: "https://lebonheurparis.com"
        }
    ],

    SESSION_REFRESH_TIMEOUT: 60000,

    IS_SALE_ON: false
}